import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalState';
import Loader from './gif/loader.svg'
import Cookies from './components/cookies/cookies'
const Home = lazy(() => import('./components/pages/Home'));
const SmileBuilder = lazy(() => import('./components/pages/SmileBuilder'));
const Thankyou = lazy(() => import('./components/pages/ThankYou'));
function App() {
  return (
    <GlobalProvider >
      <Router>
        <Suspense fallback={<div style={
          {
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }
        }>
          <img src={Loader} alt=''></img></div>}>
          <Switch>

            <Route exact path="/" component={Home} />
            <Route exact path="/smilebuilder" component={SmileBuilder} />
            <Route exact path="/thankyou" component={Thankyou} />
          </Switch>
          <Cookies />
        </Suspense>
      </Router>
    </GlobalProvider>
  );
}

export default App;
